const authUrlMap = new Map([
  ["course-cover-image", "api/attachments/courseCoverImages/uploadAuth"],
  ["course-desc-image", "api/attachments/courseDescriptionImages/uploadAuth"],
  ["video-attachments", "api/attachments/videosAttachments/uploadAuth"],
  ["question-body-image", "api/attachments/questionBodyImages/uploadAuth"],
  ["question-option-image", "api/attachments/questionOptionImages/uploadAuth"]
]);

function get_suffix(filename) {
  const pos = filename.lastIndexOf(".");
  let suffix = "";
  if (pos !== -1) {
    suffix = filename.substring(pos);
  }
  return suffix;
}

function calculate_key(suffix, baseInfo) {
  return baseInfo.dir + baseInfo.file_uuid + suffix;
}

export function getAuthUrl(fileType) {
  return authUrlMap.get(fileType);
}

export function getUploadFormData(baseInfo, file) {
  const filename = file.name;
  const key = calculate_key(get_suffix(filename), baseInfo);
  const form = new FormData();
  form.append("name", filename);
  form.append("key", key);
  form.append("policy", baseInfo.policy);
  form.append("OSSAccessKeyId", baseInfo.accessid);
  form.append("callback", baseInfo.callback);
  form.append("signature", baseInfo.signature);
  form.append("file", file);
  // Upload file to OSS
  return form;
}

export function uploadToOss(fileType, file) {
  // TODO
  return null;
}

<style lang="less">
@import "./UnitInfo.less";
</style>

<template>
  <div>
    <course-update-modal
      v-model="updateModal"
      v-bind:diskInfo="diskInfo"
      v-bind:courseCode="formItem.course_code"
      v-on:refresh="loadData()"
    ></course-update-modal>
    <course-post-modal
      v-model="postModal"
      v-bind:diskInfo="diskInfo"
      v-bind:courseCode="formItem.course_code"
      v-on:refresh="loadData()"
    ></course-post-modal>
    <attachment-upload-modal
      v-model="attachmentPostModal"
      v-bind:diskInfo="diskInfo"
      v-bind:courseCode="formItem.course_code"
      v-on:refresh="loadData()"
    ></attachment-upload-modal>
    <Row v-if="loadingCounts > 0"> {{ this.$route.query.id }} 加载中... </Row>
    <Row v-else style="width: 95%" class="main-body">
      <h1>
        {{ this.formItem.course_name }} ({{ this.formItem.semester }})
        <span v-if="!isActive" class="course-non-active"> 未激活</span>
      </h1>
      <div style="padding: 20px 0; padding-right: 20px">
        <Card class="desc-card">
          <div style="margin: 5px 0">
            <div v-if="!descEditor">
              <mavon-editor
                class="desc-view"
                :value="this.formItem.description"
                :subfield="descViewOption.subfield"
                :defaultOpen="descViewOption.defaultOpen"
                :toolbarsFlag="descViewOption.toolbarsFlag"
                :editable="descViewOption.editable"
                :scrollStyle="descViewOption.scrollStyle"
                :boxShadow="descViewOption.boxShadow"
                :previewBackground="descViewOption.previewBackground"
                :ishljs="true"
                :codeStyle="descViewOption.codeStyle"
                :imageClick="descViewOption.imageClick"
              ></mavon-editor>
              <Row v-if="privilege >= 1">
                <Button
                  class="edit-desc-button"
                  type="primary"
                  v-on:click="editDesc"
                >
                  修改课程描述</Button
                >
              </Row>
            </div>
            <div v-else>
              <mavon-editor
                class="desc-editor"
                v-model="descEditorContext"
                :toolbars="descEditorOption.toolbars"
                :ishljs="true"
                :codeStyle="descEditorOption.codeStyle"
                @save="saveDescEditor"
                @imgAdd="handleMavonImgAdd"
                @imgDel="handleMavonImgDel"
                :boxShadow="descEditorOption.boxShadow"
                :imageClick="descEditorOption.imageClick"
                :previewBackground="descEditorOption.previewBackground"
              />
              <Row>
                <Button
                  class="cancel-desc-button"
                  type="error"
                  v-on:click="descEditor = false"
                >
                  放弃修改</Button
                >
                <Button
                  class="cancel-desc-button"
                  type="primary"
                  v-on:click="saveDescEditor(descEditorContext)"
                >
                  保存修改</Button
                >
              </Row>
            </div>
          </div>
        </Card>
      </div>
      <div style="padding: 20px 0; padding-right: 20px" id="details-tab">
        <div class="demo-tabs-style1">
          <Tabs
            :value="tabName"
            type="card"
            :animated="false"
            @on-click="changeTab"
          >
            <TabPane
              class="tab-content"
              name="classInfo"
              label="上课链接"
              :index="1"
            >
              <div
                v-if="tabName === 'classInfo'"
                style="
                  padding-right: 20px;
                  padding-top: 20px;
                  padding-bottom: 20px;
                "
              >
                <Card v-if="this.courseStreaming.detail_text === 'NaN'">
                  <a
                    v-if="privilege >= 2"
                    slot="extra"
                    style="color: grey; margin-right: 2px"
                    @click="editStreaming()"
                  >
                    <icon type="md-settings" />
                  </a>
                  <p slot="title">暂无课程信息</p>
                  <p>请联系顾问更新</p>
                </Card>
                <Card v-else>
                  <a
                    v-if="privilege >= 2"
                    slot="extra"
                    style="color: grey; margin-right: 2px"
                    @click="editStreaming()"
                  >
                    <icon type="md-settings" />
                  </a>
                  <p slot="title">
                    {{
                      (this.courseStreaming.upcoming_title = getVideoTitle(
                        this.courseStreaming.detail_text
                      ))
                    }}
                  </p>

                  <div style="margin: 5px 0">
                    会议 ID：{{
                      (this.courseStreaming.mid = getStreamingMid(
                        this.courseStreaming.detail_text
                      ))
                    }}
                  </div>
                  <div style="margin: 5px 0">
                    密码：
                    {{
                      (this.courseStreaming.password = getStreamingPassword(
                        this.courseStreaming.detail_text
                      ))
                    }}
                  </div>
                  <div style="margin: 5px 0">
                    会议链接：
                    <a
                      class="break-all"
                      target="_blank"
                      :href="courseStreaming.zoomLink"
                    >
                      {{ courseStreaming.zoomLink }}
                    </a>
                    <Button
                      size="small"
                      style="color: #4c8bf5"
                      @click="copyPass(courseStreaming.zoomLink)"
                    >
                      <Icon type="md-copy" />
                    </Button>
                  </div>
                  <Button @click="showDetail()">详细信息</Button>
                </Card>
              </div>
            </TabPane>
            <TabPane
              v-if="privilege >= 1"
              name="studList"
              class="tab-content"
              label="学生列表"
              :index="2"
            >
              <div
                v-if="tabName === 'studList'"
                style="
                  padding-right: 20px;
                  padding-top: 20px;
                  padding-bottom: 20px;
                "
              >
                <p style="margin-bottom: 20px">
                  学生人数统计（付费人数/课程总人数）:
                  {{ this.totalPaidStudent }} / {{ this.totalStudent }}
                </p>
                <Table :columns="studentTable" :data="students"></Table>
              </div>
            </TabPane>
            <TabPane
              class="tab-content"
              name="videoRes"
              label="视频资料"
              :index="3"
            >
              <div
                v-if="tabName === 'videoRes'"
                style="
                  padding-right: 20px;
                  padding-top: 20px;
                  padding-bottom: 20px;
                "
              >
                <Row>
                  <Row
                    v-for="(diskInfo_2, index) in slicedDiskInfos"
                    v-bind:key="index"
                    :gutter="20"
                    class="card-row"
                  >
                    <Col
                      :lg="{ span: 12 }"
                      :xs="{ span: 24 }"
                      class="video-col"
                      span="12"
                      v-for="(diskInfo, index) in diskInfo_2"
                      v-bind:key="index"
                    >
                      <VideoCard
                        v-bind:diskInfo="diskInfo"
                        v-bind:formItem="formItem"
                        v-bind:editVideoPrivilege="editVideoPrivilege"
                        v-on:post-attachments="postAttachments($event)"
                        v-on:update-video="updateVideo($event)"
                        v-on:refresh="loadData()"
                      >
                      </VideoCard>
                    </Col>
                  </Row>
                  <Col
                    v-if="editVideoPrivilege == true"
                    :lg="{ span: 12 }"
                    :xs="{ span: 24 }"
                    span="12"
                    class="materials-item"
                  >
                    <Card style="width: 100%; height: 284px">
                      <h2
                        align="center"
                        style="font-size: 26px; padding-top: 100px"
                      >
                        <a
                          slot="extra"
                          title="添加视频"
                          @click="postVideo(diskInfo)"
                        >
                          <Icon type="ios-add-circle-outline" />添加视频
                        </a>
                      </h2>
                    </Card>
                  </Col>
                </Row>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </Row>
  </div>
</template>
<script>
import CourseUpdateModal from "../../components/course-update-modal";
import CoursePostModal from "../../components/course-post-modal";
import AttachmentUploadModal from "../../components/attachment-upload-modal";
import VideoCard from "../../components/video-card";
import { getAuthUrl, getUploadFormData } from "../../lib/attachment";

export default {
  components: {
    CourseUpdateModal,
    CoursePostModal,
    AttachmentUploadModal,
    VideoCard
  },
  data() {
    return {
      campus: "",
      loadingCounts: 1,
      isActive: true,
      deleteVideoModal: false,
      deleteAttachmentsModal: false,
      totalStudent: 0,
      totalPaidStudent: 0,
      tabName: "classInfo",
      studentTable: [
        {
          title: "账号",
          key: "username"
        },
        {
          title: "姓名",
          key: "name"
        }
      ],
      students: [],
      updateModal: false,
      postModal: false,
      attachmentPostModal: false,
      courseModalInfo: {
        rawText: ""
      },
      diskInfo: false,
      id: this.$route.query.id,
      descEditorContext: "",
      descEditor: false,
      descEditorOption: {
        toolbars: {
          bold: true, // 粗体
          italic: true, // 斜体
          header: true, // 标题
          underline: true, // 下划线
          mark: true, // 标记
          superscript: true, // 上角标
          quote: true, // 引用
          ol: true, // 有序列表
          link: true, // 链接
          imagelink: true, // 图片链接
          help: true, // 帮助
          code: true, // code
          subfield: true, // 是否需要分栏
          fullscreen: false, // 全屏编辑
          readmodel: true, // 沉浸式阅读
          /* 1.3.5 */
          undo: true, // 上一步
          redo: true,
          trash: false, // 清空
          save: true, // 保存（触发events中的save事件）
          /* 1.4.2 */
          navigation: true, // 导航目录
          table: true
        },
        codeStyle: "atom-one-light",
        imageClick: this.handleMavonImgClick,
        previewBackground: "#fff",
        boxShadow: false
      },
      descViewOption: {
        subfield: false, // 单双栏模式
        defaultOpen: "preview", //edit： 默认展示编辑区域 ， preview： 默认展示预览区域
        editable: false,
        toolbarsFlag: false,
        scrollStyle: true,
        boxShadow: false,
        previewBackground: "#fff",
        codeStyle: "atom-one-light",
        imageClick: this.handleMavonImgClick
      },
      formItem: {
        campus: "",
        faculty: "",
        course_code: "",
        course_name: "",
        teacher: "",
        price: 0.0,
        description: "",
        descImages: []
      },
      courseStreaming: {
        upcoming_title: "NaN",
        mid: "0",
        password: "NaN",
        zoomLink: "NaN",
        detail_text: "NaN",
        pre_detail_text: "NaN"
      },
      diskInfos: []
    };
  },
  methods: {
    changeTab(tabName) {
      this.$router.replace({ query: { id: this.courseCode, tab: tabName } });
      this.$nextTick(() => {
        this.tabName = tabName;
      });
    },
    handleMavonImgClick: () => {},
    handleMavonImgAdd(pos, file) {
      this.$Loading.start();
      this.axios
        .get(getAuthUrl("course-desc-image"))
        .then(resp => {
          const form = getUploadFormData(resp.data, file);
          return this.axios.post(resp.data.host, form);
        })
        .then(resp => {
          if (resp.status === 203) {
            this.$Message.error(`上传失败！不支持的文件格式`);
          } else {
            this.$Message.success("上传成功！");
            this.$Loading.finish();
            // Bind img and course
            this.axios.post(
              `api/course/${this.formItem.course_code}/description/images`,
              { filename: resp.data.filename }
            );
            this.handleMavonReplaceImgUrl(pos, resp.data.filename);
          }
        })
        .catch(error => {
          this.$Loading.error();
          this.$Message.error(`上传失败！错误信息: ${error}`);
        });
    },
    handleMavonReplaceImgUrl(pos, filename) {
      const url = `api/course/${this.formItem.course_code}/description/image/${
        filename.split("/")[2]
      }`;
      const content = this.descEditorContext;
      // Replace Img url ![...](xxx) -> ![...](url)
      const oStr = `(${pos})`;
      const nStr = `(${url})`;
      const index = content.indexOf(oStr);
      const str = content.replace(oStr, "");
      const insertStr = (source, start, newStr) => {
        return source.slice(0, start) + newStr + source.slice(start);
      };
      this.descEditorContext = insertStr(str, index, nStr);
    },
    handleMavonImgDel: () => {},
    getVideoTitle(url) {
      const pattern = /Topic: (.*)\n/;
      url = url.match(pattern);
      if (url == null) return "暂无匹配内容";
      return url[1];
    },
    postVideo(diskInfo) {
      this.diskInfo = diskInfo;
      this.postModal = true;
    },
    getVideoLink(url) {
      const pattern = /https:.*\n/g;
      url = url.match(pattern);
      if (url == null) return "暂无匹配内容";
      return url[0];
    },
    copyPass(password) {
      const el = document.createElement("textarea");
      el.value = password;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$Message.success("提取码已复制");
    },
    handleBack() {
      if (this.privilege >= 1) this.$router.push("/allunits");
      else this.$router.push("/enrolledUnits");
    },
    getStreamingMid(url) {
      const pattern = /Meeting ID: (.*)/;
      url = url.match(pattern);
      if (url == null) return "暂无匹配内容";
      return url[1];
    },
    getStreamingPassword(url) {
      const pattern = /Passcode: (.*)/;
      url = url.match(pattern);
      if (url == null) return "暂无匹配内容";
      return url[1];
    },
    showDetail() {
      this.$Modal.info({
        title: `<h2>详细信息</h2>`,
        width: "800px",
        content: `<pre>${this.courseStreaming.detail_text}</pre>`
      });
    },
    editStreaming() {
      this.$Modal.confirm({
        render: h => {
          return h("Input", {
            props: {
              value: this.courseStreaming.detail_text,
              autofocus: true,
              placeholder: "将上课链接粘贴到此处",
              type: "textarea",
              rows: 10,
              draggable: true
            },
            on: {
              input: val => {
                this.courseStreaming.detail_text = val;
              }
            }
          });
        },
        title: `<h2>上课链接</h2>`,
        width: "650px",
        scrollable: true,
        draggable: true,
        onOk: async () => {
          const mid = this.courseStreaming.mid;
          const upcoming_title = this.courseStreaming.upcoming_title;
          const password = this.courseStreaming.password;
          const text = this.courseStreaming.detail_text;
          const detail_text = {
            detail_text: text,
            mid: mid,
            upcoming_title: upcoming_title,
            password: password
          };
          try {
            const resp = await this.axios.put(
              `api/course/${this.formItem.course_code}/details/course_streaming`,
              detail_text
            );
            this.$Message.success(
              `成功修改${resp.data.upcoming_title}的详细信息！`
            );
          } catch {
            this.$Message.error("修改失败！");
          } finally {
            this.axios
              .get(
                `api/course/${this.formItem.course_code}/details/course_streaming`
              )
              .then(resp2 => {
                const courseStreaming = resp2.data;
                this.courseStreaming.upcoming_title =
                  courseStreaming.upcoming_title;
                this.courseStreaming.mid = courseStreaming.detail_text;
                this.courseStreaming.password = courseStreaming.password;
                this.courseStreaming.detail_text = courseStreaming.detail_text;
                this.courseStreaming.pre_detail_text =
                  courseStreaming.detail_text;
                this.courseStreaming.zoomLink = this.getVideoLink(
                  this.courseStreaming.detail_text
                );
              });
          }
        },
        onCancel: () => {
          this.courseStreaming.detail_text =
            this.courseStreaming.pre_detail_text;
        }
      });
    },

    // Modal triggers
    postAttachments(diskInfo) {
      this.diskInfo = diskInfo;
      this.courseModalInfo.title = diskInfo.title;
      this.courseModalInfo.link = diskInfo.title;
      this.attachmentPostModal = true;
    },
    updateVideo(diskInfo) {
      this.courseModalInfo.title = diskInfo.title;
      this.courseModalInfo.link = diskInfo.title;
      this.diskInfo = diskInfo;
      this.updateModal = true;
    },
    saveDescEditor(rawText) {
      // List used and unused images
      const pattern = /description\/image\/([\w\-.]+?)\)/gim;
      const matches = rawText.matchAll(pattern);
      const used = new Set();
      for (const each of matches) {
        used.add(each[1].toString());
      }
      const unused = this.formItem.descImages.filter(img => !used.has(img));
      const imageUsage = { used: [...used], unused: unused };
      // console.log(imageUsage);
      this.axios
        .put(`api/course/${this.id}`, {
          description: rawText,
          imageUsage: imageUsage
        })
        .then(resp => {
          if (resp.data.description === rawText) {
            this.$Message.success(`更新成功`);
            this.formItem.description = rawText;
            this.formItem.descImages = resp.data.descriptionImages;
            this.descEditor = false;
          }
        })
        .catch(() => {
          this.$Message.error(`更新失败`);
        });
    },
    editDesc() {
      this.descEditorContext = this.formItem.description;
      this.descEditor = true;
    },
    awaitRequest() {
      this.loadingCounts += 1;
    },
    finishRequest() {
      this.loadingCounts -= 1;
      if (this.loadingCounts == 0) {
        this.$Loading.finish();
      }
    },
    loadData() {
      this.$Loading.start();
      this.$Loading.finish();
      // Loading Entry
      const tabName = this.$route.query.tab;
      if (tabName != null) {
        this.$nextTick(() => {
          this.tabName = tabName;
        });
      }

      const id = this.$route.query.id;
      this.axios
        .get(`api/course/${id}`)
        .then(resp => {
          const courseInfo = resp.data;
          this.campus = courseInfo.campus;
          this.formItem.campus = courseInfo.campus;
          this.formItem.faculty = courseInfo.faculty;
          this.formItem.course_code = courseInfo.course_code;
          this.formItem.course_name = courseInfo.course_name;
          this.formItem.teacher = courseInfo.teacher;
          this.formItem.price = courseInfo.price.toFixed(2);
          this.formItem.description = courseInfo.description;
          this.formItem.semester = courseInfo.semester;
          this.formItem.descImages = courseInfo.descriptionImages;

          this.awaitRequest();
          this.axios
            .get(`api/campus/${this.campus}/semesters/current`)
            .then(resp => {
              this.isActive = !!resp.data.includes(this.formItem.semester);
            })
            .then(this.finishRequest);
          return courseInfo.course_code;
        })
        .then(code => {
          this.awaitRequest();
          this.axios
            .get(`api/course/${code}/details/course_streaming`)
            .then(resp2 => {
              const courseStreaming = resp2.data;
              this.courseStreaming.upcoming_title =
                courseStreaming.upcoming_title;
              this.courseStreaming.mid = courseStreaming.mid;
              this.courseStreaming.password = courseStreaming.password;
              this.courseStreaming.detail_text = courseStreaming.detail_text;
              this.courseStreaming.pre_detail_text =
                courseStreaming.detail_text;
              this.courseStreaming.zoomLink = this.getVideoLink(
                this.courseStreaming.detail_text
              );
            })
            .then(this.finishRequest);
          this.awaitRequest();
          this.axios
            .get(`api/course/${code}/details/course_videos`)
            .then(resp3 => {
              const diskInfos = resp3.data;
              this.diskInfos = diskInfos;
              for (const i in this.diskInfos) {
                diskInfos[i].link = this.getVideoLink(diskInfos[i].url);
                diskInfos[i].image_filename = "";
                diskInfos[i].title = this.getVideoTitle(diskInfos[i].url);
                diskInfos[i].formItem = this.formItem;
              }
            })
            .then(this.finishRequest);
          this.finishRequest();
        });
      if (this.privilege > 0) {
        this.axios.get(`api/course/${id}/students`).then(response => {
          const students = response.data;
          const total = students.length;
          let paid = 0;
          this.students = [];
          for (let i = 0; i < students.length; i++) {
            if (students[i].deal_price != 0) {
              paid += 1;
            }
            this.students.push({
              username: students[i].student,
              name: students[i].student_name
            });
          }
          this.totalStudent = total;
          this.totalPaidStudent = paid;
        });
      }
      this.$Message.config({
        top: 50,
        duration: 3
      });
    }
  },
  computed: {
    privilege: function () {
      return this.$store.getters.getCurrentUser.privilege;
    },
    courseCode: function () {
      return this.$route.query.id;
    },
    editVideoPrivilege: function () {
      if (this.privilege >= 2) {
        return true;
      } else if (
        this.privilege == 1 &&
        this.formItem.teacher == this.$store.getters.getCurrentUser.username
      ) {
        return true;
      }
      return false;
    },
    slicedDiskInfos: function () {
      const sliced = [];

      for (let index = 0; index < this.diskInfos.length; index += 2) {
        const chunck = this.diskInfos.slice(index, index + 2);
        // Do something if you want with the group
        sliced.push(chunck);
      }
      return sliced;
    }
  },
  watch: {
    courseCode() {
      this.loadData();
    }
  },
  mounted: function () {
    this.loadData();
  }
};
</script>

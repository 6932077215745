
import { Component, Prop, Vue } from "vue-property-decorator";
interface DiskInfo {
  url: string;
  title: string;
  link: string;
  vid: string;
  image: string;
  attachments: Record<string, string>[];
}
@Component
export default class VideoCard extends Vue {
  @Prop(Object) diskInfo!: DiskInfo;
  @Prop(Object) formItem!: Record<string, string>;
  @Prop(Boolean) editVideoPrivilege!: boolean;
  deleteVideoModal = false;
  get privilege() {
    return this.$store.getters.getCurrentUser.privilege;
  }
  get courseRawText() {
    return this.diskInfo.url;
  }
  set courseRawText(url) {
    this.diskInfo = {} as DiskInfo;
    this.diskInfo.url = url;
    this.diskInfo.title = this.getVideoTitle(url);
    this.diskInfo.link = this.getVideoLink(url);
  }
  copyPass(password: string) {
    const el = document.createElement("textarea");
    el.value = password;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.$Message.success("提取码已复制");
  }
  deleteVideo(vid: string) {
    this.axios
      .delete(
        `api/course/${this.formItem.course_code}/details/course_video/${vid}`
      )
      .then(resp => {
        if (resp.status === 200) {
          this.$Message.success(`删除成功`);
          this.$emit("refresh");
        }
      })
      .catch(() => {
        this.$Message.error(`删除失败`);
      });
  }
  getVideoPassword(url: string) {
    const pattern = /Pass(?:word|code): (.*[^\s])/;
    const matchResult = url.match(pattern);
    if (matchResult != null) {
      return matchResult[1];
    }
    return "暂无匹配内容";
  }
  getVideoLink(url: string) {
    const pattern = /https:.*\n/g;
    const matchResult = url.match(pattern);
    if (matchResult == null) return "暂无匹配内容";
    return matchResult[0];
  }
  getVideoTitle(url: string) {
    const pattern = /Topic: (.*)\n/;
    const matchResult = url.match(pattern);
    if (matchResult == null) return "暂无匹配内容";
    return matchResult[1];
  }
  getVideoImage(url: string) {
    if (url == null) {
      if (
        this.formItem.campus == "Monash" ||
        this.formItem.campus == "Monash University"
      )
        return require("../../assets/monash.jpeg");
      if (
        this.formItem.campus == "The University Of Melbourne" ||
        this.formItem.campus == "University of Melbourne"
      )
        return require("../../assets/melb_uni_IT.jpeg");
    }
    return url;
  }
  updateVideo() {
    this.$emit("update-video", this.diskInfo);
  }
  postAttachments() {
    this.$emit("post-attachments", this.diskInfo);
  }
  getAttachmentSuffix(filename: string) {
    const pos = filename.lastIndexOf(".");
    let suffix = "";
    if (pos != -1) {
      suffix = filename.substring(pos);
    }
    return suffix;
  }
  confirmDeleteAttachment(
    diskInfo: DiskInfo,
    attachmentsInfo: Record<string, string>
  ) {
    this.$Modal.confirm({
      title: "确认删除",
      content: "文件一经删除无法恢复，是否确认删除",
      onOk: () => {
        this.deleteAttachments(diskInfo, attachmentsInfo);
      }
    });
  }
  deleteAttachments(
    diskInfo: DiskInfo,
    attachmentsinfo: Record<string, string>
  ) {
    const attach = { link_or_name: "" };
    attach.link_or_name = attachmentsinfo.link_or_name;
    this.axios
      .delete(
        `api/course/${this.formItem.course_code}/details/course_video/${diskInfo.vid}/attachments`,
        { data: { link_or_name: attach.link_or_name } }
      )
      .then(resp => {
        if (resp.status === 200) {
          this.$Message.success("删除成功");
          this.$emit("refresh");
        }
      })
      .catch(() => {
        this.$Message.error("删除失败");
      });
  }
}

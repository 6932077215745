<style lang="less">
@import "./attachment-upload-modal.less";
</style>

<template>
  <Modal
    v-bind:value="value"
    v-on:on-visible-change="$emit('input', $event)"
    class-name="attachment-upload-modal"
    width="650px"
    v-if="true"
    @on-ok="onOk"
    @on-cancel="onCancel"
    @on-visible-change="onVisibleChange($event)"
  >
    <div class="header">
      <div
        class="ivu-modal-confirm-head-icon ivu-modal-confirm-head-icon-confirm"
      >
        <i class="ivu-icon ivu-icon-ios-help-circle" />
      </div>
      <div class="header-text ivu-modal-confirm-head-title">上传资料</div>
    </div>
    <div id="title-group">
      <span>标题:</span>
      <Input v-model="newTitle" />
    </div>
    <div id="cover-group">
      <span>资料:</span>
      <Upload
        type="drag"
        action="#"
        :max-size="10"
        :beforeUpload="beforeUpload"
        v-if="!coverUploader.done"
      >
        <div class="upload-center-image">
          <Icon
            type="ios-cloud-upload-outline"
            size="50"
            style="color: #3399ff"
          />
          <p>点击上传文件</p>
        </div>
      </Upload>
      <div>
        <p>
          {{
            coverUploader.filename == "" ? "暂无文件" : coverUploader.filename
          }}
        </p>
        <Progress :percent="coverUploader.percent" strock-width="{width}" />
      </div>
    </div>
    <p class="notice">*上传文件格式仅限pdf和zip，目前文件大小仅支持10M以内</p>
  </Modal>
</template>
<script>
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Upload } from "view-design";

export default {
  name: "AttachmentUploadModal",
  props: ["value", "diskInfo", "courseCode"],
  computed: {
    modal_title: {
      get: function () {
        return this.getVideoTitle(this.courseRawText);
      }
    },
    modal_link: {
      get: function () {
        return this.getVideoLink(this.courseRawText);
      }
    }
  },
  data: function () {
    return {
      newTitle: "",
      courseRawText: "",
      fname: "",
      fkey: "",
      coverUploader: {
        supportSuffix: [".pdf", ".zip", ".pptx"],
        filename: "",
        maxSize: 100,
        percent: 0,
        url: "",
        done: false,
        imageUploadForm: {
          name: "",
          key: "",
          policy: "",
          OSSAccessKeyId: "",
          success_action_status: 200,
          callback: "",
          signature: "",
          file: new FormData(),
          "x-oss-forbid-overwrite": "true",
          "Access-Control-Allow-Origin": "*"
        }
      }
    };
  },
  methods: {
    alertSizeExceeded(file) {
      this.$Notice.warning({
        title: "Exceeding file size limit",
        desc: "File  " + file.name + " is too large, no more than 10M."
      });
    },
    getVideoLink(url) {
      const pattern = /https:.*\n/g;
      url = url.match(pattern);
      if (url == null) return "暂无匹配内容";
      return url[0];
    },
    getVideoTitle(url) {
      const pattern = /Topic: (.*)\n/;
      url = url.match(pattern);
      if (url == null) return "暂无匹配内容";
      return url[1];
    },
    beforeUpload(file) {
      const filename = file.name;
      this.fname = file.name;
      this.coverUploader.filename = filename;
      if (
        !this.coverUploader.supportSuffix.includes(this.get_suffix(filename))
      ) {
        this.$Notice.warning({
          title: "不支持的文件格式",
          desc: "该文件格式不支持，请使用PDF和ZIP"
        });
        return false;
      }
      // handle exceed size
      if (file.size > this.maxSize * 1024 * 1024) {
        this.alertSizeExceeded(file);
        return false;
      }

      this.axios
        .get(`api/attachments/videosAttachments/uploadAuth`)
        .then(resp => {
          const baseInfo = resp.data;
          const key = this.calculate_key(this.get_suffix(filename), baseInfo);
          this.fkey = key;

          //Build the upload form
          const form = new FormData();
          form.append("name", filename);
          form.append("key", key);
          form.append("policy", baseInfo.policy);
          form.append("OSSAccessKeyId", baseInfo.accessid);
          form.append("callback", baseInfo.callback);
          form.append("signature", baseInfo.signature);
          form.append("file", file);
          this.axios
            .post(`${baseInfo.host}`, form, {
              headers: {
                "Content-Type": "multipart/form-data"
              },
              timeout: 0,
              onUploadProgress: evt => {
                let percent = 0;
                if (evt.lengthComputable) {
                  percent = Math.floor((evt.loaded / event.total) * 100);
                  if (percent > 100) {
                    percent = 100;
                  }
                }
                this.coverUploader.percent = percent;
              }
            })
            .then(resp => {
              if (resp.status == 203) {
                this.$Message.error(`上传失败！不支持的文件格式`);
                this.coverUploader.filename = "上传失败";
                this.coverUploader.percent = 0;
              } else {
                this.$Message.success("上传完成！");
                this.diskInfo.image_filename = resp.data.filename;
                this.coverUploader.done = true;
                const index = this.fname.lastIndexOf(".");
                if (index != -1) {
                  this.newTitle = this.fname.substring(0, index);
                } else {
                  this.newTitle = this.fname;
                }
                const suffixtype = this.get_suffix(this.fname).substring(1);
              }
            })
            .catch(error => {
              this.$Message.error(`上传失败！错误信息: ${error}`);
              this.coverUploader.percent = 0;
              this.coverUploader.filename = "上传失败";
            });
        });

      return false;
    },
    get_suffix(filename) {
      const pos = filename.lastIndexOf(".");
      let suffix = "";
      if (pos != -1) {
        suffix = filename.substring(pos);
      }
      return suffix;
    },
    calculate_key(suffix, baseInfo) {
      return baseInfo.dir + baseInfo.file_uuid + suffix;
    },
    onOk() {
      const diskInfo = this.diskInfo;
      const video = {};
      video.title = this.newTitle;
      video.type = this.get_suffix(this.fname).substring(1);
      video.link_or_name = this.fkey;

      this.axios
        .post(
          `api/course/${this.courseCode}/details/course_video/${diskInfo.vid}/attachments`,
          video
        )
        .then(resp => {
          if (resp.status === 200) {
            this.$Message.success("上传成功！");
            this.$emit("refresh", true);
          }
        })
        .catch(() => {
          this.$Message.error("上传失败");
          this.$emit("refresh", true);
        });
      this.$Modal.remove();
    },
    onCancel() {
      this.$Modal.remove();
    },
    onVisibleChange(e) {
      if (e == true) {
        this.courseRawText = this.diskInfo.url;
        this.coverUploader.supportSuffix = [".pdf", ".zip"];
        this.coverUploader.filename = "";
        this.coverUploader.percent = 0;
        this.coverUploader.done = false;
      }
    }
  }
};
</script>

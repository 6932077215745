<style lang="less">
@import "./course-update-modal.less";
</style>

<template>
  <Modal
    v-bind:value="value"
    v-on:on-visible-change="$emit('input', $event)"
    class-name="course-update-modal"
    width="650px"
    v-if="true"
    @on-ok="onOk"
    @on-cancel="onCancel"
    @on-visible-change="onVisibleChange($event)"
  >
    <div class="header">
      <div
        class="ivu-modal-confirm-head-icon ivu-modal-confirm-head-icon-confirm"
      >
        <i class="ivu-icon ivu-icon-ios-help-circle" />
      </div>
      <div class="header-text ivu-modal-confirm-head-title">修改视频</div>
    </div>
    <div id="title-group">
      <span>标题:</span>
      <Input v-model="modal_title" disabled />
    </div>
    <div id="link-group">
      <span>链接:</span>
      <Input id="url" v-model="modal_link" disabled />
    </div>
    <div id="text-group">
      <span>文本:</span>
      <Input
        type="textarea"
        id="raw-text"
        v-model="courseRawText"
        v-bind:rows="10"
      />
    </div>
    <div id="cover-group">
      <span> 封面:</span>
      <Upload
        type="drag"
        action="#"
        :beforeUpload="beforeUpload"
        :on-exceeded-size="handleMaxSize"
        :max-size="coverUploader.maxSize"
        v-if="!coverUploader.done"
      >
        <div class="upload-center-image">
          <Icon type="ios-cloud-upload" size="52" style="color: #3399ff" />
          <p>拖拽或点击以上传图片</p>
        </div>
      </Upload>
      <img
        v-else
        v-bind:src="coverUploader.image"
        height="100px"
        width="240px"
      />
      <div>
        <p>
          {{
            coverUploader.filename == "" ? "暂无文件" : coverUploader.filename
          }}
        </p>
        <Progress :percent="coverUploader.percent" strock-width="{width}" />
      </div>
    </div>
  </Modal>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { Upload } from "view-design";
export default {
  name: "CourseUpdateModal",
  props: ["value", "diskInfo", "courseCode"],
  computed: {
    modal_title: {
      get: function () {
        return this.getVideoTitle(this.courseRawText);
      }
    },
    modal_link: {
      get: function () {
        return this.getVideoLink(this.courseRawText);
      }
    }
  },
  data: function () {
    return {
      courseRawText: "",
      coverUploader: {
        supportSuffix: [".jpg", ".jpeg", ".png"],
        filename: "",
        maxSize: 2048,
        percent: 0,
        url: "",
        done: false,
        imageUploadForm: {
          name: "",
          key: "",
          policy: "",
          OSSAccessKeyId: "",
          success_action_status: 200,
          callback: "",
          signature: "",
          file: new Image(),
          "x-oss-forbid-overwrite": "true",
          "Access-Control-Allow-Origin": "*"
        }
      }
    };
  },
  methods: {
    handleMaxSize(file) {
      this.$Notice.warning({
        title: "Exceeding file size limit",
        desc: "File  " + file.name + " is too large, no more than 2M."
      });
    },
    getVideoLink(url) {
      const pattern = /https:.*\n/g;
      url = url.match(pattern);
      if (url == null) return "暂无匹配内容";
      return url[0];
    },
    getVideoTitle(url) {
      const pattern = /Topic: (.*)\n/;
      url = url.match(pattern);
      if (url == null) return "暂无匹配内容";
      return url[1];
    },
    beforeUpload(file) {
      const filename = file.name;
      this.coverUploader.filename = filename;
      if (
        !this.coverUploader.supportSuffix.includes(this.get_suffix(filename))
      ) {
        this.$Notice.warning({
          title: "不支持的文件格式",
          desc: "该文件格式不支持，请使用JPEG/PNG"
        });
        return false;
      }

      this.axios
        .get(`api/attachments/courseCoverImages/uploadAuth`)
        .then(resp => {
          const baseInfo = resp.data;
          const key = this.calculate_key(this.get_suffix(filename), baseInfo);

          //Build the upload form
          const form = new FormData();
          form.append("name", filename);
          form.append("key", key);
          form.append("policy", baseInfo.policy);
          form.append("OSSAccessKeyId", baseInfo.accessid);
          form.append("callback", baseInfo.callback);
          form.append("signature", baseInfo.signature);
          form.append("file", file);

          this.axios
            .post(`${baseInfo.host}`, form, {
              onUploadProgress: evt => {
                let percent = 0;
                if (evt.lengthComputable) {
                  percent = Math.floor((evt.loaded / event.total) * 100);
                  if (percent > 100) {
                    percent = 100;
                  }
                }
                this.coverUploader.percent = percent;
              }
            })
            .then(resp => {
              if (resp.status === 203) {
                this.$Message.error(`上传失败！不支持的文件格式`);
                this.coverUploader.filename = "上传失败";
                this.coverUploader.percent = 0;
              } else {
                this.$Message.success("上传成功！");
                this.diskInfo.image_filename = resp.data.filename;
                this.coverUploader.done = true;
                this.coverUploader.image = resp.data.image;
              }
            })
            .catch(error => {
              this.$Message.error(`上传失败！错误信息: ${error}`);
              this.coverUploader.percent = 0;
              this.coverUploader.filename = "上传失败";
            });
        });

      return false;
    },
    get_suffix(filename) {
      const pos = filename.lastIndexOf(".");
      let suffix = "";
      if (pos != -1) {
        suffix = filename.substring(pos);
      }
      return suffix;
    },
    calculate_key(suffix, baseInfo) {
      return baseInfo.dir + baseInfo.file_uuid + suffix;
    },
    onOk() {
      const diskInfo = this.diskInfo;
      const video = {};
      video.title = this.modal_title;
      video.url = this.courseRawText;
      if (
        Object.prototype.hasOwnProperty.call(diskInfo, "image_filename") &&
        diskInfo.image_filename != ""
      ) {
        video.image_filename = diskInfo.image_filename;
      }
      this.axios
        .put(
          `api/course/${this.courseCode}/details/course_video/${diskInfo.vid}`,
          video
        )
        .then(resp => {
          if (resp.status === 200) {
            this.$Message.success("修改成功！");

            this.$emit("refresh");
          }
        })
        .catch(() => {
          this.$Message.error("修改失败");
          this.$emit("refresh");
        });
      this.$Modal.remove();
    },
    onCancel() {
      //Do nothing
    },
    onVisibleChange(e) {
      if (e == true) {
        this.courseRawText = this.diskInfo.url;
        this.coverUploader.supportSuffix = [".jpg", ".jpeg"];
        this.coverUploader.filename = "";
        this.coverUploader.percent = 0;
        this.coverUploader.done = false;
      }
    }
  }
};
</script>
